import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const PortfolioList = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulProjectList {
        title
        projects {
          name
          title
          description
          feature {
            fixed(width: 350) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcSetWebp
              srcWebp
              width
            }
          }
        }
      }
    }
  `);
  return (
    <section id="two">
      {
          data.contentfulProjectList.title
          ? <h2>{data.contentfulProjectList.title}</h2>
          : null
        }
      <div className="row" >
        {data.contentfulProjectList.projects.map((project, i) => (
          <article
            key={i}
            className="6u 12u$(xsmall) work-item"
          >
            <Link
              to={`/portfolio/${project.name}/`}
              className="image fit thumb"
            >
              <Img fixed={project.feature.fixed} />
            </Link>
            <Link to={`/portfolio/${project.name}/`}>
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </Link>
          </article>
        ))}
      </div>
    </section>
  );
}

export default PortfolioList;
